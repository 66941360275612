<template>
    <div>
        <div class="accountSecurity">
            <div class="accountSecurity-header">账号安全</div>
            <div class="accountSecurity-content">
                <div class="accountSecurity-title">
                    <span style="padding-right:20px;">二次登录校验</span>
                    <div style="display: inline-block">
                        <el-switch v-model="loginCheckEnabled" active-color="#67C23A" inactive-color="#F56C6C"
                            active-text="开启" inactive-text="关闭" @change="change(loginCheckEnabled)">
                        </el-switch>
                    </div>
                </div>
                <div class="accountSecurity-text">
                    当您开启该功能，则登录管理员后台时，输入正确的账号、密码、图像验证提交后，还需进行短信验证码校验，短信验证码校验成功后才能登录成功。
                </div>
                <div class="accountSecurity-title">
                    二次登录校验使用说明：
                </div>
                <div class="accountSecurity-text">
                    1. 当您的账号没有填写正确的手机号码时，系统会提醒您先修改手机号码再开启该功能；<br>
                    2. 当您的账号没有填写正确的手机号码或者手机号码无法收到验证码时，您可以修改手机号码，成功收到验证码后才可开启该功能；<br>
                    3. 当您不需要开启该功能后，则登录后，在右上角-账号-账号安全-关闭该功能。
                </div>
            </div>
        </div>
        <el-dialog title="二次身份校验" :visible.sync="dialogVisible" :close-on-click-modal="false" @close="cancelBtn">
            <div>
                <el-form ref="myForm" :model="form" :rules="rules">
                    <!-- 有手机号码 -->
                    <div v-if="hasLoginCheckMobile">
                        <div v-if="!updatePhoneFlage">
                            <div style="padding-bottom:20px;padding-left:80px;">请输入手机号码 <span style="padding: 0 6px;">{{
                                    loginCheckPhoneStr
                            }}</span>收到的验证码</div>
                            <el-form-item label-width='80px' prop="smsCode">
                                <el-input maxlength="50" style="width: 320px;display: inline-block;"
                                    placeholder="请输入验证码" v-model.trim="form.smsCode" clearable>
                                </el-input>
                                <el-button type="primary" :loading="loading" class="getcode" @click="send">
                                    <span v-show="!showTite">{{ this.count }}s</span>
                                    <span v-show="showTite">获取验证码</span>
                                </el-button>
                            </el-form-item>
                            <div style="margin-bottom:20px;margin-left:80px;color:#30BAC1;cursor: pointer;width: 100px;"
                                @click="UnHasCode()">
                                收不到验证码？
                            </div>
                        </div>
                        <div v-if="updatePhoneFlage">
                            <div style="padding-bottom:20px;padding-left:20px;">请输入新的手机号码 </div>
                            <el-form-item label-width='20px' prop="newPhone">
                                <el-input maxlength="11" style="width: 320px;display: inline-block;"
                                    placeholder="请输入新手机号码" v-model.trim="form.newPhone" clearable>
                                </el-input>
                                <el-button type="primary" :loading="loading" class="getcode" @click="send">
                                    <span v-show="!showTite">{{ this.count }}s</span>
                                    <span v-show="showTite">获取验证码</span>
                                </el-button>
                            </el-form-item>
                            <el-form-item label-width='20px' prop="smsCode">
                                <el-input maxlength="8" style="width: 320px;display: inline-block;" placeholder="请输入验证码"
                                    v-model.trim="form.smsCode" clearable>
                                </el-input>
                            </el-form-item>
                        </div>
                    </div>

                    <!-- 无手机号码 -->
                    <div v-if="!hasLoginCheckMobile">
                        <div style="padding-bottom:20px;padding-left:20px;">系统没有检测到您的手机号码，无法开启二次身份校验，请填写手机号码后提交，即可开启该功能。
                        </div>
                        <el-form-item label-width='20px' prop="newPhone">
                            <el-input maxlength="11" style="width: 320px;display: inline-block;" placeholder="请输入新手机号码"
                                v-model.trim="form.newPhone" clearable>
                            </el-input>
                            <el-button type="primary" :loading="loading" class="getcode" @click="send">
                                <span v-show="!showTite">{{ this.count }}s</span>
                                <span v-show="showTite">获取验证码</span>
                            </el-button>
                        </el-form-item>
                        <el-form-item label-width='20px' prop="smsCode">
                            <el-input maxlength="8" style="width: 320px;display: inline-block;" placeholder="请输入验证码"
                                v-model.trim="form.smsCode" clearable type="phone">
                            </el-input>
                        </el-form-item>

                    </div>

                </el-form>
            </div>
            <div class="submitBtn">
                <el-button type="primary" @click="submit()">提交</el-button>
            </div>
        </el-dialog>

        <Verify @success="verifiedBy" :mode="'pop'" :captchaType="'blockPuzzle'" :imgSize="{ width: '330px', height: '155px' }"
      ref="verify1"></Verify>

    </div>

</template>
<script>
import ruleVerification from "@utils/ruleVerification.js"
const TIME_COUNT = 60; //更改倒计时时间
export default {
    data() {
        return {
            loading: false,
            userId: "",
            userInfo: "",
            show: true, // 初始启用按钮
            showTite: true, // 初始启用按钮
            count: "", // 初始化次数
            timer: null,
            loginCheckEnabled: false,
            dialogVisible: false,
            updatePhoneFlage: false,
            hasLoginCheckMobile: false,
            loginCheckPhoneStr: '',
            loginCheckMobile: "",
            form: {
                smsCode: '',
                newPhone: '',
            },
            rules: {
                smsCode: [{
                    required: true,
                    message: "请输入验证码",
                    trigger: ['change'],
                }],
                newPhone: [{
                    required: true,
                    // message: "请输入新手机号码",
                    // trigger: ['change'],
                    trigger: ['change'],
                    validator: ruleVerification.validMobile
                }],
            },
        }
    },
    activated() {
        console.log('账号安全activated')
        this.userId = localStorage.getItem("userId");
        this.userInfo = this.cache.getLS("userInfo");
        this.loginCheckMobile = this.userInfo.loginCheckMobile;
        this.form.newPhone = this.loginCheckMobile
        this.loginCheckEnabled = this.userInfo.loginCheckEnabled == 1;
        if (!this.loginCheckMobile) {
            this.hasLoginCheckMobile = false;
        } else {
            this.hasLoginCheckMobile = true;
        }
        this.count = '';
        this.showTite = true;
        clearInterval(this.timer); // 清除定时器
        this.timer = null;
    },
    deactivated() {
        this.dialogVisible = false;
    },
    methods: {
        change(e) {
            this.count = '';
            this.showTite = true;
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
            // 初始化
            this.form.smsCode = '';
            this.form.newPhone = '';
            if (!this.userInfo.loginCheckMobile) {
                this.hasLoginCheckMobile = false;
            } else {
                this.hasLoginCheckMobile = true;
            }
            if (e) {
                this.dialogVisible = true;
            } else {
                this.dialogVisible = false;
                this.submit();
            }
            this.updatePhoneFlage = false;
            if (this.userInfo.loginCheckMobile) {              
                let phpneLegth = this.userInfo.loginCheckMobile.length;
                this.loginCheckPhoneStr = this.userInfo.loginCheckMobile.slice(0, 3) + '***' + this.userInfo
                    .loginCheckMobile.slice(
                        phpneLegth - 4, phpneLegth)
            }

            this.$nextTick(() => {
                if (this.$refs["myForm"]) {
                    this.$refs["myForm"].resetFields();
                }
            })
        },
        submit() {
            // 需要填新手机号码
            if (!this.hasLoginCheckMobile || this.updatePhoneFlage) {
                if(this.form.newPhone) {
                    this.loginCheckMobile = this.form.newPhone;
                }
            }else if(!this.updatePhoneFlage) {
                this.loginCheckMobile = this.userInfo.loginCheckMobile;
            }
            let dto = {
                userId: this.userId,
                loginCheckMobile: this.loginCheckMobile,
                loginCheckEnabled: this.loginCheckEnabled ? 1 : 0,
            }
            let url = 'user-service/user/modifyCheckMobile';
            if (this.dialogVisible) {
                this.$refs["myForm"].validate((valid) => {
                    if (valid) {
                        if (this.dialogVisible) {
                            dto.smsCode = this.form.smsCode;
                        }
                        this.post(url, dto, {
                            isUseResponse: true,
                        }).then(res => {
                            if (res.data.code == 0) {
                                this.dialogVisible = false;
                                this.$message({
                                    message: '修改成功！',
                                    type: 'success',
                                })
                                this.loginCheckMobile = dto.loginCheckMobile
                                this.userInfo.loginCheckMobile = dto.loginCheckMobile;
                                this.userInfo.loginCheckEnabled = dto.loginCheckEnabled;
                                this.hasLoginCheckMobile = true
                                this.cache.setLS('userInfo', this.userInfo)
                                this.form.smsCode = ''
                            }
                        })
                    }
                })
            } else {
                this.post(url, dto, {
                    isUseResponse: true,
                }).then(res => {
                    if (res.data.code == 0) {
                        this.dialogVisible = false;
                        this.$message({
                            message: '修改成功！',
                            type: 'success',
                        })
                        this.loginCheckMobile = dto.loginCheckMobile
                        this.userInfo.loginCheckMobile = dto.loginCheckMobile;
                        this.userInfo.loginCheckEnabled = dto.loginCheckEnabled;
                        this.hasLoginCheckMobile = true
                        this.cache.setLS('userInfo', this.userInfo)
                        this.form.smsCode = ''
                    }
                })
            }
        },
        cancelBtn() {
            this.loginCheckEnabled = this.userInfo.loginCheckEnabled == 1;
        },
        UnHasCode() {
            if (!this.userInfo.loginCheckMobile) {
                this.hasLoginCheckMobile = false;
            } else {
                this.hasLoginCheckMobile = true;
            }
            this.updatePhoneFlage = true;
            this.count = '';
            this.showTite = true;
            this.form.smsCode = '';
            clearInterval(this.timer); // 清除定时器
            this.timer = null;
            this.$nextTick(() => {
                if (this.$refs["myForm"]) {
                    this.$refs["myForm"].resetFields();
                }
            })
        },
        send() {
            if (!this.showTite) return

            console.log('获取验证码')

            if (!this.hasLoginCheckMobile || this.updatePhoneFlage) {
                if(this.form.newPhone) {
                    this.loginCheckMobile = this.form.newPhone;
                }
            }else if(!this.updatePhoneFlage) {
                this.loginCheckMobile = this.userInfo.loginCheckMobile;
            }
            
            if (this.updatePhoneFlage || !this.hasLoginCheckMobile) {
                var reg = /^1[3-9](\d){9}$/;
                if (!this.form.newPhone) {
                    return this.$message({
                        message: '请输入手机号！',
                        type: "warning"
                    })
                }
                else if (reg.test(this.form.newPhone) == false) {
                    return this.$message({
                        message: '请输入正确的手机号！',
                        type: "warning"
                    })
                }
            }
            this.$refs.verify1.refresh();
            this.$refs.verify1.show();
        },
        onSend(captchaVerification) {
            let dto = {
                mobile: this.loginCheckMobile,
                captchaVerification: captchaVerification,
            };
            if (!this.timer) {
                this.loading = true;
                this.post("user-service/weChat/sendMobileCode", dto).then(res => {
                    console.log(res);
                    this.show = false;
                    this.count = TIME_COUNT;
                    this.showTite = false;
                    this.loading = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.showTite = true;
                            this.show = true;
                            clearInterval(this.timer); // 清除定时器
                            this.timer = null;
                        }
                    }, 1000);
                    this.$message({
                        message: '发送成功！',
                        type: 'success',
                    })
                }).catch(err => {
                    this.loading = false
                })
            }
        },
        verifiedBy(params) {
            this.onSend(params.captchaVerification);
        },
    }
}
</script>
<style lang="scss" scoped>
.accountSecurity {
    color: #333333;
    font-size: 16px;
}

.accountSecurity-header {
    border-bottom: 1px solid #c0c4cc;
    height: 46px;
    line-height: 46px;
    color: #333333;
}

.accountSecurity-content {
    padding-left: 46px;
}

.accountSecurity-title {
    margin-bottom: 14px;
    margin-top: 50px;
}

.accountSecurity-text {
    color: #909399;
    font-size: 15px;
    line-height: 35px;
}

.getcode {
    margin-left: 10px;
}

.submitBtn {
    text-align: center;
}

/deep/.el-dialog {
    width: 585px;
    top: 15vh;
}
</style>