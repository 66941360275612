var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "accountSecurity" }, [
        _c("div", { staticClass: "accountSecurity-header" }, [
          _vm._v("账号安全")
        ]),
        _c("div", { staticClass: "accountSecurity-content" }, [
          _c("div", { staticClass: "accountSecurity-title" }, [
            _c("span", { staticStyle: { "padding-right": "20px" } }, [
              _vm._v("二次登录校验")
            ]),
            _c(
              "div",
              { staticStyle: { display: "inline-block" } },
              [
                _c("el-switch", {
                  attrs: {
                    "active-color": "#67C23A",
                    "inactive-color": "#F56C6C",
                    "active-text": "开启",
                    "inactive-text": "关闭"
                  },
                  on: {
                    change: function($event) {
                      _vm.change(_vm.loginCheckEnabled)
                    }
                  },
                  model: {
                    value: _vm.loginCheckEnabled,
                    callback: function($$v) {
                      _vm.loginCheckEnabled = $$v
                    },
                    expression: "loginCheckEnabled"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "accountSecurity-text" }, [
            _vm._v(
              "\n                当您开启该功能，则登录管理员后台时，输入正确的账号、密码、图像验证提交后，还需进行短信验证码校验，短信验证码校验成功后才能登录成功。\n            "
            )
          ]),
          _c("div", { staticClass: "accountSecurity-title" }, [
            _vm._v("\n                二次登录校验使用说明：\n            ")
          ]),
          _vm._m(0)
        ])
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "二次身份校验",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.cancelBtn
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { ref: "myForm", attrs: { model: _vm.form, rules: _vm.rules } },
                [
                  _vm.hasLoginCheckMobile
                    ? _c("div", [
                        !_vm.updatePhoneFlage
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-bottom": "20px",
                                      "padding-left": "80px"
                                    }
                                  },
                                  [
                                    _vm._v("请输入手机号码 "),
                                    _c(
                                      "span",
                                      { staticStyle: { padding: "0 6px" } },
                                      [_vm._v(_vm._s(_vm.loginCheckPhoneStr))]
                                    ),
                                    _vm._v("收到的验证码")
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "80px",
                                      prop: "smsCode"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "320px",
                                        display: "inline-block"
                                      },
                                      attrs: {
                                        maxlength: "50",
                                        placeholder: "请输入验证码",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.form.smsCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "smsCode",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.smsCode"
                                      }
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "getcode",
                                        attrs: {
                                          type: "primary",
                                          loading: _vm.loading
                                        },
                                        on: { click: _vm.send }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.showTite,
                                                expression: "!showTite"
                                              }
                                            ]
                                          },
                                          [_vm._v(_vm._s(this.count) + "s")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.showTite,
                                                expression: "showTite"
                                              }
                                            ]
                                          },
                                          [_vm._v("获取验证码")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-bottom": "20px",
                                      "margin-left": "80px",
                                      color: "#30BAC1",
                                      cursor: "pointer",
                                      width: "100px"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.UnHasCode()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            收不到验证码？\n                        "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.updatePhoneFlage
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-bottom": "20px",
                                      "padding-left": "20px"
                                    }
                                  },
                                  [_vm._v("请输入新的手机号码 ")]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "20px",
                                      prop: "newPhone"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "320px",
                                        display: "inline-block"
                                      },
                                      attrs: {
                                        maxlength: "11",
                                        placeholder: "请输入新手机号码",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.form.newPhone,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "newPhone",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.newPhone"
                                      }
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "getcode",
                                        attrs: {
                                          type: "primary",
                                          loading: _vm.loading
                                        },
                                        on: { click: _vm.send }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.showTite,
                                                expression: "!showTite"
                                              }
                                            ]
                                          },
                                          [_vm._v(_vm._s(this.count) + "s")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.showTite,
                                                expression: "showTite"
                                              }
                                            ]
                                          },
                                          [_vm._v("获取验证码")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "20px",
                                      prop: "smsCode"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "320px",
                                        display: "inline-block"
                                      },
                                      attrs: {
                                        maxlength: "8",
                                        placeholder: "请输入验证码",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.form.smsCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "smsCode",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.smsCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e(),
                  !_vm.hasLoginCheckMobile
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "padding-bottom": "20px",
                                "padding-left": "20px"
                              }
                            },
                            [
                              _vm._v(
                                "系统没有检测到您的手机号码，无法开启二次身份校验，请填写手机号码后提交，即可开启该功能。\n                    "
                              )
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { "label-width": "20px", prop: "newPhone" }
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "320px",
                                  display: "inline-block"
                                },
                                attrs: {
                                  maxlength: "11",
                                  placeholder: "请输入新手机号码",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.form.newPhone,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "newPhone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.newPhone"
                                }
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "getcode",
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading
                                  },
                                  on: { click: _vm.send }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.showTite,
                                          expression: "!showTite"
                                        }
                                      ]
                                    },
                                    [_vm._v(_vm._s(this.count) + "s")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showTite,
                                          expression: "showTite"
                                        }
                                      ]
                                    },
                                    [_vm._v("获取验证码")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { "label-width": "20px", prop: "smsCode" }
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "320px",
                                  display: "inline-block"
                                },
                                attrs: {
                                  maxlength: "8",
                                  placeholder: "请输入验证码",
                                  clearable: "",
                                  type: "phone"
                                },
                                model: {
                                  value: _vm.form.smsCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "smsCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.smsCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "submitBtn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.submit()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ]
      ),
      _c("Verify", {
        ref: "verify1",
        attrs: {
          mode: "pop",
          captchaType: "blockPuzzle",
          imgSize: { width: "330px", height: "155px" }
        },
        on: { success: _vm.verifiedBy }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "accountSecurity-text" }, [
      _vm._v(
        "\n                1. 当您的账号没有填写正确的手机号码时，系统会提醒您先修改手机号码再开启该功能；"
      ),
      _c("br"),
      _vm._v(
        "\n                2. 当您的账号没有填写正确的手机号码或者手机号码无法收到验证码时，您可以修改手机号码，成功收到验证码后才可开启该功能；"
      ),
      _c("br"),
      _vm._v(
        "\n                3. 当您不需要开启该功能后，则登录后，在右上角-账号-账号安全-关闭该功能。\n            "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }